/* You can add global styles to this file, and also import other style files */
html, body {
    overflow-x: hidden;
    min-height: 100vh;
  }

body { padding-right: 0 !important }

tr {border-bottom: 1px solid #dee2e6 !important;}

.main-componente {
  width: 100%;
  min-height: 100vh;
  /* background: url(/assets/background.svg) 0 0 / contain no-repeat #F5F6F8; */
}

.form-group {
  margin-bottom: 0.5rem !important;
}

.toast-header {
  display: block;
}


.toast-header > .toast-header-line {
  float: right;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-primary, .btn-info {
  color: #fff;
  background-color: #06bfcd;
  border-color: #e7e7e7;
}
.btn-primary:hover, .btn-info:hover{
  background-color:#17a2b8 !important;
  border-color: #e7e7e7;
}


.ui-datepicker td{
  cursor: pointer;
} 

.ui-datepicker {
  z-index: 9999999 !important ;
} 

.card-header {
  padding: 3px;
}

.col-automargin {
  margin-top: auto;
  margin-bottom: auto;
}

td[scope]{
  font-weight: bold;
  color: #24377c !important;
}
.cont_flex{display: flex;}
.cont_flex #sidebar-menu .sidebar-navigation{float: none;margin-right:0;background: none !important;}

/*.cont_flex #sidebar-menu {background-color:#ededed; padding: 5px 0}*/
.cont_flex #sidebar-menu {background-color:#dedede; padding: 5px 0}
.cont_flex #taskArea{width: 100%;}
.cont_flex #taskArea .container-fluid
,.cont_flex #taskArea .container{padding-right: 20px;padding-left: 20px;
padding-top: 20px;}

.sidebar-navigation ul li i {
  color: #24377c;/*opacity: 0.8*/
}
.sidebar-navigation ul li:hover i{opacity: 1}

.page-item.active .page-link{
  background-color: #17a2b8;
    border-color: #17a2b8;
}

.paginate_button.page-item .page-link{color:#17a2b8;}
.paginate_button.page-item.active .page-link{color:#ffffff;}
.sidebar-navigation ul li:hover{background-color: #d4d4d4 !important;}
.sidebar-navigation ul li .tooltip{background-color:#dedede !important}
.sidebar-navigation ul li .tooltip .title:hover{background-color: #dedede !important;}

.sidebar-navigation ul li a {
  color: #24377c !important;
}